<template>
	<div class="home">
		<div class="home_center">
			<!-- 推荐用户 -->
			<Homeleft @chat="openchart" @change="change"></Homeleft>
			<!-- 动态列表 -->
			<Msglist v-if="!show"  ref="list" @detail="detail"></Msglist>
			<!-- 动态列表详情 -->
			<Msglistdetail v-if="show" :trend_id="trend_id" @goback="goback"></Msglistdetail>
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import Msglist from '../../components/home/msg_list.vue';
	import Msglistdetail from '../../components/home/msg_list_detail.vue';
	import Homeleft from '../../components/home/home_left.vue';
	import Reply from '../../components/home/reply.vue';
	import Pinlun from '../../components/home/pinlun.vue';
	export default{
		data(){
			return{
				show:false,
				trend_id:''
			};
		},
		components:{
			Homeright,
			Msglist,
			Msglistdetail,
			Homeleft,
			Reply,
			Pinlun
		},
		created(){
			if(this.$route.query.trend_id){
				this.trend_id=this.$route.query.trend_id;
				this.show=true;
			}else{
				this.show=false;
			}
		},
		watch:{
			'$route':{
				handler:function(){
					//location.reload();
					console.log(1111111111111);
					if(this.$route.query.trend_id){
						this.trend_id=this.$route.query.trend_id;
						this.show=true;
					}else{
						this.show=false;
					}
				}
			}
		},
		methods:{
			goback(){
				this.show=false;
			},
			detail(id){
				this.show=true;
				this.trend_id=id;
			},
			openchart(obj){
				//console.log('obj',obj);
				this.$emit('chat',{user_id:obj.user_id});
			},
			change(n){				
				this.$refs.list.tab(n);
			},
		}
	};
</script>

<style lang="less" scoped>
	.home{
		min-height: 100%;
		background-color: #212121;
		.home_center{
			display: flex;
			justify-content: flex-end;
		}
	}
</style>