<template>
	<div class="home_left">
		<div class="left_top_btn">
			<el-button type="primary" :class="tabIndex==1?'active_btn':'top_btn'" @click="tab(1)"><!-- 动态广场 -->{{$t('home.DTGC')}}</el-button>
			<el-button type="primary" :class="tabIndex==2?'active_btn':'top_btn'" @click="tab(2)"><!-- 关注动态 -->{{$t('home.GZDT')}}</el-button>
			<el-button type="primary" :class="tabIndex==3?'active_btn':'top_btn'" @click="tab(3)"><!-- 我的动态 -->{{$t('home.WDDT')}}</el-button>
		</div>
		<div class="tuijian">
			<div class="tui_title">
				<div class="title_left">
					<!-- 推荐用户 -->{{$t("home.TJYH")}}
				</div>
				<!-- <img src="../../assets/img/home_right_menu.png" class="menu_img" alt=""> -->
			</div>
			<div class="tui_list">
				<div class="list" v-for="item in users" :key="item.user_id">
					<router-link :to='"/people_page?user_id="+item.user_id'>
						<img :src="item.avatar_url" class="list_img" alt="">
					</router-link>
					<div class="list_msg">
						{{item.open_name}}
					</div>
					<div class="list_msg">
						{{item.description||'-'}}
					</div>
					<div class="list_msg">
						<!-- 职业 -->{{$t("ZHIYE")}}：{{item.profession}}
					</div>
					<div class="list_msg">
						<!-- 年龄 -->{{$t("task.LN")}}：{{item.age}}
					</div>
					<!-- <div class="list_msg">
						评估：{{item.score}}
					</div>
					<div class="list_msg">
						时间单价：{{item.hope_wage}}
					</div> -->
					<el-button type="primary" class="list_btn" @click="sendmsg(item.user_id)"><!-- 发送消息 -->{{$t('FSXX')}}</el-button>
				</div>				
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				users:[],
				tabIndex:1
			};
		},
		created() {
			this.getUser();
		},
		methods:{
			tab(n){
				this.tabIndex=n;
				this.$emit('change',n);
			},
			sendmsg(id){
				this.$emit('chat',{user_id:id});
			},
			async getUser(){
				let res=await this.$request.get('/api/custuser/recommendCust');
				this.users=res.data.records;
			},
		}
		
	};
</script>

<style lang="less" scoped>
	.home_left{
		width: 470px;
		.left_top_btn{
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-bottom: 40px;
			.top_btn{
				width: 291px;
				height: 51px;
				background: #212121;
				border-radius: 3px;
				border: 1px solid #567BB6;
				margin-left: 0;
				margin-bottom: 10px;
				color: #fff;
			}
			.top_btn:hover{
				width: 291px;
				height: 51px;
				background: #567BB6;
				border-radius: 3px;
				margin-left: 0;
				border: none;
				margin-bottom: 10px;
				color: #fff;
			}
			.active_btn{
				width: 291px;
				height: 51px;
				background: #567BB6;
				border-radius: 3px;
				margin-left: 0;
				border: none;
				margin-bottom: 10px;
				color: #fff;
			}
			
		}
		.tuijian{
			border: 2px solid #567BB6;
			border-radius: 3px;
			padding: 35px 18px;
			.tui_title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 50px;
				.title_left{
					font-size: 21px;
					color: #fff;
				}
				.menu_img{
					width: 30px;
					height: 20px;
				}
			}
			.tui_list{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.list{
					width: 208px;
					margin-bottom: 50px;
					.list_img{
						width: 208px;
						height: 210px;
						margin-bottom: 20px;
						border-radius: 5px;
						object-fit: cover;
					}
					.list_msg{
						width: 208px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 14px;
						color: #fff;
						margin-bottom: 10px;
					}
					.list_btn{
						width: 208px;
						height: 21px;
						background: #567BB6;
						border-radius: 3px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
					}
				}
			}
		}
	}
</style>