<template>
	<div class="pinlun">
		<el-dialog title="10条回复" :visible.sync="dialogVisible" width="50%" >
			<div class="pin_list">
				
				<div class="list_box">
					<img src="../../assets/img/user1.png" class="box_img" alt="">
					<div class="box_right">
						<div class="right_user">
							<div class="user_name">
								<div class="name">
									Teppei Mishima / 创意总监
				
								</div>
								<div class="name_time">
									8月15日 8:15
								</div>
							</div>
							<div class="user_btn">
								<!-- <img src="../../assets/img/home_zan.png" class="btn1" alt=""> -->
								<img src="../../assets/img/home_zan1.png" class="btn1" alt="">
								<img src="../../assets/img/home_btn2.png" class="btn2" alt="">
								<img src="../../assets/img/home_btn4.png" class="btn3" alt="">
							</div>
						</div>
						<div class="right_msg">
							好久不见！实际上去年成立了制作公司。虽然是只有几名员工的小公司，但是因为正好有副业可以拜托的网络制作的工作，如果可以的话要不要说一次呢？另外给您发聊天吧。
						</div>
						<!-- 评论回复 -->
						<div class="right_more">
							<div class="more_list">
								<div class="list_text">
									<div class="text_name">
										Sota Takeda：
									</div>
									<div class="text_msg">
										回复
										<span>Teppei Mishima：</span>
									</div>
									<div class="more_user">
										好的，回头见！好的，回头见！好的，回头见！好的，回头见！好的，回头见！好的，回头见！好的，回头见！好的，回头见！好…
									</div>
									<div class="user_btn">
										<img src="../../assets/img/home_zan.png" class="btn1" alt="">
										<!-- <img src="../../assets/img/home_zan1.png" class="btn1" alt=""> -->
										<img src="../../assets/img/home_btn2.png" class="btn2" alt="">
										<img src="../../assets/img/home_btn4.png" class="btn3" alt="">
									</div>
								</div>
								<div class="list_time">
									8月15日 8:17
								</div>
							</div>
							<div class="more_list">
								<div class="list_text">
									<div class="text_name">
										Sota Takeda：
									</div>
									<div class="text_msg">
										回复
										<span>Teppei Mishima：</span>
									</div>
									<div class="more_user">
										好的，回头见！
									</div>
									<div class="user_btn">
										<img src="../../assets/img/home_zan.png" class="btn1" alt="">
										<!-- <img src="../../assets/img/home_zan1.png" class="btn1" alt=""> -->
										<img src="../../assets/img/home_btn2.png" class="btn2" alt="">
										<img src="../../assets/img/home_btn4.png" class="btn3" alt="">
									</div>
								</div>
								<div class="list_time">
									8月15日 8:17
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: true
			}
		},
		methods: {
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
				 .catch(_ => {});
			}
		}
	}
</script>

<style lang="less" scoped>
	.pinlun {
		background-color: #F3F1EA;
		.el-dialog{
			background-color: #F3F1EA;
		}
		.pin_list{
			width: 100%;
			.list_box{
				width: 100%;
				margin: 15px 0;
				display: flex;
				.box_img{
					width: 34px;
					height: 34px;
					margin-right: 15px;
					border-radius: 2px;
					flex-shrink: 0;
				}
				.box_right{
					width: 100%;
					
					.right_user{
						display: flex;
						justify-content: space-between;
						align-items: center;
						.user_name{
							.name{
								width: 300px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: normal;
								font-size: 12px;
								margin-bottom: 10px;
								font-weight: bold;
							}
							.name_time{
								font-size: 10px;
							}
						}
						.user_btn{
							display: flex;
							align-items: center;
							flex-shrink: 0;
							// display: none;
							.btn1{
								width: 24px;
								height: 24px;
								margin-left: 20px;
							}
							.btn2{
								width: 21px;
								height: 21px;
								margin-left: 20px;
							}
							.btn3{
								width: 24px;
								height: 24px;
								margin-left: 20px;
							}
						}
					}
					.right_msg{
						font-size: 12px;
						margin: 10px 0;
						line-height: 1.3;
					}
					.right_more{
						font-size: 10px;
						// display: flex;
						// align-items: center;
						border-left: 1px solid #DADADA;
						padding-left: 5px;
						.more_list:hover{
							.user_btn{
								display: flex;
							}
						}
						.list_time{
							font-size: 10px;
							color: #757575;
							margin-bottom: 20px;
						}
						.more_list{
							.list_text{
								display: flex;
								font-size: 10px;
								line-height: 1.3;
								margin-bottom: 10px;
							}
							.text_name{
								flex-shrink: 0;
								line-height: 1.3;
							}
							.text_msg{
								flex-shrink: 0;
								color: #567BB6;
								line-height: 1.3;
								span{
									color: #2B2B2A;
								}
							}
							.user_btn{
								// display: flex;
								display: none;
								align-items: center;
								.btn1{
									width: 12px;
									height: 12px;
									margin-left: 10px;
								}
								.btn2{
									width: 11px;
									height: 11px;
									margin-left: 10px;
								}
								.btn3{
									width: 12px;
									height: 12px;
									margin-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
