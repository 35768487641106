<template>
	<div class="home_msg_list">
		<div class="home_list">
			<div class="list_bt">
				<div class="bt_left" @click="back" style="cursor: pointer;">
					<img src="../../assets/img/arrow-left-bold.png" class="left" alt="">
					<!-- 返回 -->{{$t("home.FH")}}
				</div>
				<!-- <div class="bt_left">
					用户名
				</div> -->
			</div>
		</div>
		<div class="home_list"  v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
			<div class="list_title">
				
				<img :src="datas.avatar_url" class="title_img" alt="" @click.stop="toUserPage(datas.user_id,datas.user_role)" style="cursor: pointer;">
				
				<div  @click.stop="toUserPage(datas.user_id,datas.user_role)" class="list_user">
					<div class="user">
						{{datas.open_name}} / {{datas.profession}}
					</div>
					<div class="user_msg">
						{{datas.create_time}}
					</div>
				</div>
				<div class="flex-item"></div>
				<template v-if="tabIndex!=3">
				<el-button class="active" size="small" @click="guanzhu(datas.user_id,datas.user_role,index)" v-if="datas.is_fans==1"><!-- 已关注 -->{{$t('home.YGZ')}}</el-button>
				<el-button type="primary"  size="small" @click="guanzhu(datas.user_id,datas.user_role,index)" v-else><!-- 关注 -->{{$t('FOLLOW')}}</el-button>
				</template>
			</div>
			<div class="list_text">
				{{datas.content}}
			</div>
			<!-- <div class="activity_box">
				<img src="../../assets/img/user1.png" class="box_img" alt="">
				<div class="box_msg">
					<div class="msg_name">
						高级概念设计师 东京安安安安安安高级概念设计师 东京安安安安安安高级概念设计师 东京安安安安安安高级概念设计师 东京安安安安安安
					</div>
					<div class="msg_msg">
						文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本
					</div>
					<div class="msg_text">
						必要技能：web开发
					</div>
				</div>
				<div class="box_center">
					<div class="center_text">
						<div class="text_text">
							时间
						</div>
						<div class="text_box">
							2022/8/1~2022/8/1
						</div>
					</div>
					<div class="center_text">
						<div class="text_text">
							委托方式
						</div>
						<div class="text_box">
							名称
						</div>
					</div>
					<div class="center_text">
						<div class="text_text">
							佣金
						</div>
						<div class="text_box">
							500/h
						</div>
					</div>
					<div class="center_text">
						<div class="text_text">
							需要人数
						</div>
						<div class="text_box">
							10
						</div>
					</div>
				</div>
				<div class="box_btn">
					<div class="btn">
						<img src="../../assets/img/fengx.png" class="fenx" alt="">
						分享
					</div>
					<div class="btn">
						<img src="../../assets/img/biaoq.png" class="fenx" alt="">
						保存
					</div>
				</div>
			
			</div> -->
			<template  v-if="datas.trend_type==2">
			<div class="img_list" v-if="datas.media_files">
				<video :src="datas.media_files[0]" controls style="width:480px;height:270px; background: #000;"></video>
			</div>
			</template>
			<template v-else>
			<div class="img_list" v-if="datas.media_files" @click.stop="">
				<!-- <img :src="img" class="img" alt="" v-for="img in datas.media_files" :key="img"> -->
				<!-- <el-image :src="img" :fit="fit" class="img"></el-image> -->
				<el-image :src="img" class="img" v-for="img in datas.media_files" :key="img"
				   fit="contain" :preview-src-list="datas.media_files" @click.stop="">
				  </el-image>
			</div>
			</template>
			<!-- <div class="img_list" v-if="datas.media_files" >
				<img :src="img" class="img" alt="" v-for="img in datas.media_files" :key="img">
			</div> -->
			
			<div class="like_box">
				
				<img src="../../assets/img/like_fill.png" class="like_img" alt="">
				{{datas.favor_desc}}
			</div>
			<div class="btn_box">
				<div class="btn_left">
					<div class="left_menu" @click="zan(datas.trend_id,index)">
						
						<img src="../../assets/img/home_zan1.png" class="btn1" alt="" v-if="datas.is_favor==1" >
						<img src="../../assets/img/home_zan.png" class="btn1" alt="" v-else>
						{{datas.favor_count}}
					</div>
					<div class="left_menu" >
						<img src="../../assets/img/home_btn2.png" class="btn2" alt="">
						{{datas.comment_count}}
					</div>
					<div class="left_menu">
						<img src="../../assets/img/home_btn3.png" class="btn3" alt="">
						{{datas.share_count}}
						<div class="sharemenu">
							<div @click="share(trend_id,'facebook',datas.content)">Facebook</div>
							<div @click="share(trend_id,'Linkedin',datas.content)">LinkedIn</div>
							<div @click="share(trend_id,'twitter',datas.content)">Twitter</div>
						</div>
					</div>
				</div>
				
				<!-- <img src="../../assets/img/home_btn4.png" class="right_btn" alt="" @click="todetail(datas.trend_id)"> -->
			</div>
			
				<!-- 评论 -->
				
				<el-input class="input_box" id="plbox" :placeholder="$t('QSR')" v-model="comment_content"></el-input>
				<div class="input_add">
					<biaoqing inputId="plbox" ref="biaoqing"></biaoqing>
					<el-button type="primary" size="mini" @click="sendComment"><!-- 评论 -->{{$t('task.PL3')}}</el-button>
					
				</div>
				<!-- 评论列表 -->
				<div class="pin_list">
					<div class="list_box" v-for="(comment,cindex) in comment_list" :key="comment.trend_comment_id">
						
						<img :src="comment.avatar_url" style="cursor: pointer;"  @click.stop="toUserPage(comment.user_id,comment.user_role)" class="box_img" alt="">
						
						<div class="box_right">
							<div class="right_user">
								<div @click.stop="toUserPage(comment.user_id,comment.user_role)" class="user_name">
									<div class="name">
										{{comment.open_name}}/ {{comment.profession}}<template v-if="comment.reply_comment_id"> &gt; {{comment.reply_open_name}}</template>
		
									</div>
									<div class="name_time">
										{{$util.rTime(comment.create_time)}}
									</div>
								</div>
								<div class="flex-item"></div>
								<div class="user_btn">									
									<img src="../../assets/img/home_zan1.png" class="btn1" style="cursor: pointer;" v-if="comment.is_favor==1" alt="" @click="zanComment(comment.trend_comment_id,cindex)">
									<img src="../../assets/img/home_zan.png" class="btn1" style="cursor: pointer;" alt="" v-else @click="zanComment(comment.trend_comment_id,cindex)">
									<img src="../../assets/img/home_btn2.png" class="btn2" style="cursor: pointer;" alt="" @click="openhuifu(comment.trend_comment_id,comment.open_name)">
									<!-- <img src="../../assets/img/home_btn4.png" class="btn3" alt=""> -->
								</div>
							</div>
							<div class="right_msg" v-html="comment.content">
								
							</div>
							<!-- 评论回复 -->
							<!-- <div class="right_more">
								<div class="more_user">
									Sota Takeda 
								</div>
								等人
								<div class="more_user">
									共10条回复
								</div>
							</div> -->
						</div>
					</div>
					
				</div>
				<!-- 查看全部 -->
				<div class="pin_more">
					<!-- <el-button @click="getCommentList">{{comment_isLast==0?$t('CKGD'):$t('YJDDL')+'~'}}</el-button> -->
					<el-button @click="getCommentList">{{comment_isLast==0?$t('CKGD'):''}}</el-button>
				</div>
			
		</div>
		<div style="text-align: center;font-size: 12px; color: #999;margin-top: 15px;" v-if="isLast==1">{{$t('YJDDL')}}~</div>
		
		<!-- 回复弹窗 -->
		<Reply ref="huifu" :id="reply_comment_id" :trend_id="trend_id" :name="reply_comment_name" @send="handleSend"></Reply>
		
		
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Reply from '../../components/home/reply.vue';
	import Pinlun from '../../components/home/pinlun.vue';
	import biaoqing from '../../components/biaoqing.vue';
	export default{
		data(){
			return{
				
				
				input:'',
				dialogImageUrl: '',
				dialogVisible: false,
				datas:{},
				
			
				pageSize:15,
				currentPage:1,
				isLast:0,
				list:[],
				loading:false,
				media_files:[],
				content:'',
				trend_type:0,
				
				comment_pageSize:15,
				comment_currentPage:1,
				comment_isLast:0,
				comment_list:[],
				comment_content:'',
				
				reply_comment_id:0,
				reply_comment_name:'',
				comment_content2:'',
				
				dialogImageUrl: '',
				dialogVisible: false,
				input:'',
				
				tabIndex:1,
				user_role:0,
				user_id:'',
				
			};
		},
		components:{
			Reply,
			Pinlun,
			biaoqing
		},
		props:{
			trend_id:''
		},
		created() {
			console.log(this.trend_id);
			this.getDetail();
			this.getCommentList();
		},
		methods: {
			
			
			
			share:function(id,type,title){
				var href=this.$config.website+'home?trend_id='+id;
				this.$request.get('/api/trend/share',{trend_id:id});
				this.$util.share(href,type,title);
			},
			//返回
			back(){
				//this.$emit('goback');
				//this.$router.push({path:'/home'});
				this.$router.go(-1);
			},
			async getDetail(){
				let res=await this.$request.get('/api/trend/getTrend',{trend_id:this.trend_id});
				this.datas=res.data;
			},
			//关注用户
			async guanzhu(id,role,index){
				let res=await this.$request.get('/api/friends/follow',{user_id:id,user_role:role});
				if(this.list[index].is_fans==0){
					this.$util.guanzhu(id);
					this.list[index].is_fans=1;
				}else{
					this.$util.quguan(id);
					this.list[index].is_fans=0;
				}
				this.getDetail();
			},
			//回复评论成功
			handleSend(){
				this.getCommentList('reload');
			},
			//打开回复评论弹框
			openhuifu(id,name){				
				this.reply_comment_id=id;
				this.reply_comment_name=name;
				this.$refs.huifu.open();
			},
			//点赞评论
			async zanComment(id,index){
				let res=await this.$request.get('/api/trend/favorComment',{trend_comment_id :id});				
				this.comment_list[index].is_favor=this.comment_list[index].is_favor==1?0:1;				
			},
			//点赞动态
			async zan(id,index){
				
				let res=await this.$request.get('/api/trend/favor',{trend_id:id});
				this.getDetail();
			},
			
			//评论动态
			async sendComment(){
				
				let val=this.$refs.biaoqing.getval();
				console.log(this.$refs.biaoqing);
				//return;
				let res=await this.$request.post('/api/trend/comment',{trend_id:this.trend_id,content:val});
				this.$message({
					message:res.message,
					type:'success'
				});
				this.comment_content='';
				this.getDetail();
				this.getCommentList('reload');
			},
			
			//获取动态评论列表
			async getCommentList(t){
				if(t=='reload'){
					this.comment_currentPage=1;
					this.comment_isLast=0;					
				}
				if(this.comment_isLast==1){
					return;
				}
				let res=await this.$request.post('/api/trend/commentPage',{trend_id:this.trend_id,currentPage:this.comment_currentPage,pageSize:this.comment_pageSize});
				
				
				
				if(t=='reload'){
					this.comment_list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.comment_list=[...this.comment_list,...res.data.records];
					}						
				}
				if(this.comment_currentPage>=res.data.totalPage){
					this.comment_isLast=1;
				}else{
					this.comment_currentPage+=1;
				}
				
			},
		  handleRemove(file, fileList) {
			console.log(file, fileList);
		  },
		  handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		  }
		}
	};
</script>

<style lang="less" scoped>
	
	.home_msg_list{
		width: 643px;
		margin: 0 50px;
		
		.home_list{
			width: 100%;
			border-radius: 7px;
			box-sizing: border-box;
			padding: 17px 35px;
			background-color: #F3F1EA;
			margin-bottom: 17px;
			.list_bt{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.bt_left{
					display: flex;
					align-items: center;
					font-size: 17px;
					color: #2B2B2A;
					font-weight: bold;
					.left{
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
			}
			.activity_box{
				padding: 15px;
				background-color: #000000;
				border-radius: 5px;
				margin: 10px 0;
				display: flex;
				justify-content: space-between;
				.box_img{
					width: 100px;
					height: 100px;
					border-radius: 3px;
					
				}
				.box_msg{
					width: 170px;
					font-size: 12px;
					color: #fff;
					.msg_name{
						text-overflow: -o-ellipsis-lastline;
						  overflow: hidden;
						  text-overflow: ellipsis;
						  display: -webkit-box;
						  -webkit-line-clamp: 2;
						  line-clamp: 2;
						  -webkit-box-orient: vertical;
						  line-height: 1.3;
						  margin-bottom: 10px;
					}
					.msg_msg{
						width: 170px;
						font-size: 10px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						line-height: 1.3;
						margin-bottom: 10px;
					}
					.msg_text{
						font-size: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
				.box_center{
					width: 180px;
					border-left: 1px solid #606060;
					border-right: 1px solid #606060;
					padding: 0 10px;
					color: #fff;
					font-size: 10px;
					.center_text{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 5px;
						.text_text{
							width: 50px;
							flex-shrink: 0;
						}
						.text_box{
							border-radius: 3px;
							width: 130px;
							line-height: 20px;
							border: 1px solid #344A6E;
							padding-left: 5px;
							
						}
					}
				}
				.box_btn{
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 40px;
					color: #fff;
					font-size: 10px;
					.btn{
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 5px 0;
						.fenx{
							width: 20px;
							height: 20px;
							margin-bottom: 10px;
						}
					}
				}
			}
			.list_title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 17px;
				.title_img{
					width: 42px;
					height: 42px;
					border-radius: 2px;
					// margin-right: 20px;
				}
				.list_user{
					
					.user{
						font-size: 14px;
						margin-bottom: 10px;
						font-weight: bold;
					}
					.user_msg{
						font-size: 12px;
					}
				}
				
				.el-button{
					background-color: #567BB6;
					border: none;
				}
				.active{
					color: #2D2D2E;
					background-color: #F3F1EA;
					border: 1px solid #567BB6;
				}
			}
			.list_text{
				font-size: 14px;
				line-height: 1.3;
				margin-bottom: 20px;
			}
			.img_list{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.img{
					width: 280px;
					height: 191px;
					margin-bottom: 9px;
				}
			}
			.like_box{
				height: 30px;
				padding: 0 8px;
				display: flex;
				align-items: center;
				border-top: 1px solid #567BB6;
				font-size: 12px;
				.like_img{
					width: 20px;
					height: 20px;
					margin-right: 6px;
				}
			}
			.btn_box{
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 45px;
				border-top: 1px solid #567BB6;
				border-bottom: 1px solid #567BB6;
				.btn_left{
					display: flex;
					align-items: center;
					margin-left: 85px;
					.left_menu{
						display: flex;
						align-items: center;
						margin-right: 70px;
						font-size: 14px;
						font-weight: bold;
						position: relative;
						&:hover .sharemenu{display: block;}
						.btn1{
							width: 24px;
							height: 24px;
							margin-right: 8px;
						}
						.btn2{
							width: 21px;
							height: 21px;
							margin-right: 8px;
						}
						.btn3{
							width: 19px;
							height: 22px;
							margin-right: 8px;
						}
					}
				}
				.right_btn{
					width: 27px;
					height: 27px;
				}
			}
			.input_box{
				width: 100%;
				height: 38px;
				background: #FFFFFF;
				border-radius: 9px;
				margin: 15px 0;
				box-sizing: border-box;
				font-size: 14px;
			}
			.input_add{
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 42px;
				border-bottom: 1px solid #567BB6;
				.biaoqin{
					width: 25px;
					height: 25px;
				}
				.el-button{
					background-color: #567BB6;
					border: none;
				}
			}
			.pin_list{
				width: 100%;
				.list_box{
					width: 100%;
					margin: 15px 0;
					display: flex;
					.box_img{
						width: 34px;
						height: 34px;
						margin-right: 15px;
						border-radius: 2px;
						flex-shrink: 0;
						object-fit: cover;
					}
					.box_right{
						width: 100%;
						.right_user{
							display: flex;
							justify-content: space-between;
							align-items: center;
							.user_name{
								.name{
									
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: normal;
									font-size: 12px;
									margin-bottom: 10px;
									font-weight: bold;
								}
								.name_time{
									font-size: 10px;
								}
							}
							.user_btn{
								display: flex;
								align-items: center;
								.btn1{
									width: 24px;
									height: 24px;
									margin-left: 20px;
								}
								.btn2{
									width: 21px;
									height: 21px;
									margin-left: 20px;
								}
								.btn3{
									width: 24px;
									height: 24px;
									margin-left: 20px;
								}
							}
						}
						.right_msg{
							font-size: 12px;
							margin: 10px 0;
							line-height: 1.3;
							img{width: 22px;height: 22px;vertical-align: middle;}
						}
						.right_more{
							font-size: 10px;
							display: flex;
							align-items: center;
							border-left: 1px solid #DADADA;
							.more_user{
								color: #567BB6;
								margin: 0 5px;
							}
						}
					}
				}
			}
			.pin_more{
				font-size: 14px;
				text-align: center;
				padding-top: 15px;
				border-top: 1px solid #567BB6;
				.el-button{
					background-color: #F3F1EA;
					border: none;
					font-size: 14px;
					color: #2D2D2E;
					font-weight: bold;
				}
			}
		}
	
	}
</style>