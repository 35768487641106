<template>
	<div class="home_msg_list">
		<div class="home_list_add" v-loading="isupload">
			<!-- 添加动态 -->
			<div @click.stop="toUserPage(user_id,user_role)">
				<img :src="avatar_url" class="user" alt=""  style="object-fit: cover;cursor: pointer;">
			</div>
			<div class="list_right">
				<el-input type="textarea" v-model="content" maxlength="140" id="msgbox" class="input" :placeholder="$t('activity.YSMXFXGDJD')"></el-input>
				<div style="text-align: right;font-size: 14px;padding-bottom: 10px;color:#666">{{content.length}}/140</div>
				<!-- <biaoqing inputId="msgbox" ref="biaoqing2"></biaoqing> -->
				<div class="right_img_list" v-show="trend_type==1">
					<el-upload
					id="imgbox"
					  :action="$config.uploadUrl"
					  list-type="picture-card"
					  :on-preview="handlePictureCardPreview"
					  :before-upload="imgbeforupload"
					  accept=".jpg,.png,.gif,.tif,.bmp"
					  :on-remove="handleRemove" :on-success="uploadImage" :file-list="FileList" multiple>
					  <i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
					  <img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</div>
				<div class="right_img_list" v-show="trend_type==2" >
					<div style="position: relative;" v-if="media_files2.length>0">
						<video :src="media_files2[0]" controls style="width: 320px;height:180px;background-color: #000;"></video>
					</div>
					<el-upload
					 id="videobox"
					  :action="$config.uploadUrl"
					  :show-file-list="false"
					  list-type="picture-card"
					  :before-upload="beforupload"
					   :on-success="uploadVideo" 
					   accept=".mp4,.mov,.3gp,.m4v,.avi,.webm,.m3u8,.rmvb,.mkv,.vob"
					   >
					  <i class="el-icon-video-camera"></i>
					</el-upload>
					
				</div>
				<div class="right_last">
					<div class="last_menu">
						<div class="menu" @click="select_img" style="cursor: pointer;">
							<img src="../../assets/img/home_list1.png" style="width: 24px;	height: 24px;" class="list_img" alt="">
							<!-- 图片 -->{{$t('activity.TP')}}
						</div>
						<div class="menu" @click="select_video" style="cursor: pointer;">
							<img src="../../assets/img/home_list2.png" style="	height: 24px;" class="list_img" alt="">
							<!-- 视频 -->{{$t('activity.SP')}}
						</div>
						<!-- <div class="menu">
							<img src="../../assets/img/home_list3.png" class="list_img" alt=""> -->
							<!-- 活动 --><!-- {{$t('activity.HD')}} -->
						<!-- </div> -->
					</div>
					<el-button type="primary" @click="fabu"><!-- 发送 -->{{$t('FS')}}</el-button>
				</div>
			</div>
			
			
		</div>
		<!-- <div class="home_list">
			<div class="list_title">
				<img src="../../assets/img/user1.png" class="title_img" alt="">
				<div class="list_user">
					<div class="user">
						Sota Takeda / 平面设计师
					</div>
					<div class="user_msg">
						8月15日 8:15
					</div>
				</div>
				<el-button type="primary"  size="small">关注</el-button>
			</div>
			<div class="list_text">
				现在在东京都内的广告代理店作为设计师工作，副业，将来讨论独立。我想和有同样想法的人和已经独立的人说一次。有谁能抽出时间吗？
			</div>
			<div class="like_box">
				<img src="../../assets/img/like_fill.png" class="like_img" alt="">
				石、黑、哲、其他20人
			</div>
			<div class="btn_box">
				<div class="btn_left">
					<div class="left_menu">
						<img src="../../assets/img/home_zan.png" class="btn1" alt="">
						463
					</div>
					<div class="left_menu">
						<img src="../../assets/img/home_btn2.png" class="btn2" alt="">
						463
					</div>
					<div class="left_menu">
						<img src="../../assets/img/home_btn3.png" class="btn3" alt="">
						463
					</div>
				</div>
				<img src="../../assets/img/home_btn4.png" class="right_btn" alt="">
			</div>
		</div> -->
		
		<div class="home_list" v-for="(item,index) in list" :key="item.trend_id" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
			<div @click="todetail(item.trend_id)">
				<div class="list_title"   style="cursor: pointer;">
					
						<img :src="item.avatar_url" class="title_img" alt="" @click.stop="toUserPage(item.user_id,item.user_role)">
					
					<div @click.stop="toUserPage(item.user_id,item.user_role)" style="cursor: pointer;" class="list_user">
						<div class="user">
							{{item.open_name}} / {{item.profession}}
						</div>
						<div class="user_msg">
							{{item.create_time}}
						</div>
					</div>
					<div class="flex-item"></div>
					<template v-if="item.user_id!=user_id">
					<el-button class="active" size="small" @click.stop="guanzhu(item.user_id,item.user_role,index)" v-if="item.is_fans==1"><!-- 已关注 -->{{$t('home.YGZ')}}</el-button>
					<el-button type="primary"  size="small" @click.stop="guanzhu(item.user_id,item.user_role,index)" v-else><!-- 关注 -->{{$t('FOLLOW')}}</el-button>
					</template>
					<template v-else>					
						<el-button type="primary"  size="small" @click.stop="del(item.trend_id)" ><!-- 删除 -->{{$t('activity.SC')}}</el-button>
					</template>
				</div>
			
				<div class="list_text" v-html="item.content">
					
				</div>
				<!-- <div class="activity_box">
					<img src="../../assets/img/user1.png" class="box_img" alt="">
					<div class="box_msg">
						<div class="msg_name">
							高级概念设计师 东京安安安安安安高级概念设计师 东京安安安安安安高级概念设计师 东京安安安安安安高级概念设计师 东京安安安安安安
						</div>
						<div class="msg_msg">
							文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本
						</div>
						<div class="msg_text">
							必要技能：web开发
						</div>
					</div>
					<div class="box_center">
						<div class="center_text">
							<div class="text_text">
								时间
							</div>
							<div class="text_box">
								2022/8/1~2022/8/1
							</div>
						</div>
						<div class="center_text">
							<div class="text_text">
								委托方式
							</div>
							<div class="text_box">
								名称
							</div>
						</div>
						<div class="center_text">
							<div class="text_text">
								佣金
							</div>
							<div class="text_box">
								500/h
							</div>
						</div>
						<div class="center_text">
							<div class="text_text">
								需要人数
							</div>
							<div class="text_box">
								10
							</div>
						</div>
					</div>
					<div class="box_btn">
						<div class="btn">
							<img src="../../assets/img/fengx.png" class="fenx" alt="">
							分享
						</div>
						<div class="btn">
							<img src="../../assets/img/biaoq.png" class="fenx" alt="">
							保存
						</div>
					</div>
				
				</div> -->
				<template  v-if="item.trend_type==2">
				<div class="img_list" v-if="item.media_files">
					<video :src="item.media_files[0]" controls style="width:480px;height:270px; background: #000;"></video>
				</div>
				</template>
				<template v-else>
				<div class="img_list" v-if="item.media_files" >
					<!-- <img :src="img" class="img" alt="" v-for="img in item.media_files" :key="img"> -->
					<!-- <el-image :src="img" :fit="fit" class="img"></el-image> -->
					<el-image :src="img" class="img" v-for="img in item.media_files" :key="img"					    
					   fit="contain" :preview-src-list="item.media_files" @click.stop="">
					  </el-image>
				</div>
				</template>
				<div class="like_box">
					<img src="../../assets/img/like_fill.png" class="like_img" alt="">
					{{item.favor_desc}}
				</div>
			</div>
			<div class="btn_box">
				<div class="btn_left">
					<div class="left_menu" @click="zan(item.trend_id,index)">
						
						<img src="../../assets/img/home_zan1.png" class="btn1" alt="" v-if="item.is_favor==1" >
						<img src="../../assets/img/home_zan.png" class="btn1" alt="" v-else>
						{{item.favor_count}}
					</div>
					<div class="left_menu" @click.stop="toggleComment(item.trend_id)">
						<img src="../../assets/img/home_btn2.png" class="btn2" alt="">
						{{item.comment_count}}
					</div>
					<div class="left_menu" >
						<img src="../../assets/img/home_btn3.png" class="btn3" alt="">
						{{item.share_count}}
						<div class="sharemenu">
							<div @click="share(item.trend_id,'facebook',item.content)">Facebook</div>
							<div @click="share(item.trend_id,'Linkedin',item.content)">LinkedIn</div>
							<div @click="share(item.trend_id,'twitter',item.content)">Twitter</div>
						</div>
						<!-- <el-dropdown>
						  <img src="../../assets/img/home_btn3.png" class="btn3" alt="">
						  {{item.share_count}}
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item @click="share(item.trend_id)">黄金糕</el-dropdown-item>
						    <el-dropdown-item @click="share(item.trend_id)">狮子头</el-dropdown-item>
						    <el-dropdown-item @click="share(item.trend_id)">螺蛳粉</el-dropdown-item>
						   
						  </el-dropdown-menu>
						</el-dropdown> -->
					</div>
				</div>
				
				<div class="mmorebtn" v-if="item.user_id!=user_id">
					<!-- <img src="../../assets/img/home_btn4.png" class="right_btn" style="cursor: pointer;" alt="" @click="todetail(item.trend_id)"> -->
					<img src="../../assets/img/home_btn4.png" class="right_btn" style="cursor: pointer;" alt="" >
					<div class="sharemenu">
						<div @click.stop="jubao(item.trend_id)">{{$t('JUBAO')}}</div>
					</div>
				</div>
				<img src="../../assets/img/home_btn4.png" class="right_btn" alt="" v-else @click="todetail(item.trend_id)">
			</div>
			<template v-if="trend_id==item.trend_id">
				<!-- 评论 -->
				<el-input class="input_box" id="plbox" :placeholder="$t('QSR')" v-model="comment_content"></el-input>
				<div class="input_add">
					<biaoqing inputId="plbox" ref="biaoqing"></biaoqing>
					<!-- <img src="../../assets/img/biaoqin.png" class="biaoqin" alt=""> -->
					<el-button type="primary" size="mini" @click="sendComment"><!-- 评论 -->{{$t('task.PL3')}}</el-button>
				</div>
				<!-- 评论列表 -->
				<div class="pin_list">
					<div class="list_box" v-for="(comment,cindex) in comment_list" :key="comment.trend_comment_id">
						
							<img :src="comment.avatar_url" style="cursor: pointer;" class="box_img" alt="" @click.stop="toUserPage(comment.user_id,comment.user_role)">
						
						<div class="box_right">
							<div class="right_user">
								<div class="user_name" @click.stop="toUserPage(comment.user_id,comment.user_role)">
									<div class="name">
										{{comment.open_name}}/ {{comment.profession}}<template v-if="comment.reply_comment_id"> &gt; {{comment.reply_open_name}}</template>

									</div>
									<div class="name_time">
										{{$util.rTime(comment.create_time)}}
									</div>
								</div>
								<div class="flex-item"></div>
								<div class="user_btn">									
									<img src="../../assets/img/home_zan1.png" class="btn1" v-if="comment.is_favor==1" alt="" @click="zanComment(comment.trend_comment_id,cindex)">
									<img src="../../assets/img/home_zan.png" class="btn1" alt="" v-else @click="zanComment(comment.trend_comment_id,cindex)">
									<img src="../../assets/img/home_btn2.png" class="btn2" alt="" @click="openhuifu(comment.trend_comment_id,comment.open_name)">
									<!-- <img src="../../assets/img/home_btn4.png" class="btn3" alt=""> -->
								</div>
							</div>
							<div class="right_msg" v-html="comment.content">
								
							</div>
							<!-- 评论回复 -->
							<!-- <div class="right_more">
								<div class="more_user">
									Sota Takeda 
								</div>
								等人
								<div class="more_user">
									共10条回复
								</div>
							</div> -->
						</div>
					</div>
					
				</div>
				<!-- 查看全部 -->
				<div class="pin_more">
					<el-button @click="getCommentList">{{comment_isLast==0?$t('CKGD'):''}}</el-button>
				</div>
			</template>
		</div>
		<!-- <div style="text-align: center;font-size: 12px; color: #999;margin-top: 15px;" v-if="isLast==1">{{$t('YJDDL')}}~</div> -->
		
		
		<!-- 回复弹窗 -->
		<Reply ref="huifu" :id="reply_comment_id" :trend_id="trend_id" :name="reply_comment_name" @send="handleSend"></Reply>
		
		<jubao ref="jubao" ></jubao>
		<!-- 全部评论 -->
		<!-- <Pinlun v-if="showpinglun"></Pinlun> -->
	</div>
</template>

<script>
	
	import Reply from '../../components/home/reply.vue';
	import jubao from '../../components/home/jubao.vue';
	import Pinlun from '../../components/home/pinlun.vue';	
	import scroll from '@/utils/scroll.js';
	import biaoqing from '../../components/biaoqing.vue';
	import {debounce} from '@/utils/debounce.js';
	export default{
		data(){
			return{
				FileList:[],
				showhuifu:false,
				showpinglun:false,
				pageSize:5,
				currentPage:1,
				isupload:false,
				isLast:0,
				list:[],
				loading:false,
				media_files:[],
				media_files2:[],
				content:'',
				trend_type:1,
				trend_id:0,
				comment_pageSize:15,
				comment_currentPage:1,
				comment_isLast:0,
				comment_list:[],
				comment_content:'',
				
				reply_comment_id:0,
				reply_comment_name:'',
				comment_content2:'',
				
				dialogImageUrl: '',
				dialogVisible: false,
				input:'',
				
				tabIndex:1,
				user_role:0,
				user_id:'',
				
				
			};
		},
		components:{
			Reply,
			Pinlun,
			biaoqing,
			jubao
		},
		
		created() {
			let options={
				
				//To_Account:['15'],
				From_Account:this.$local.get('user_id')+'',
				StartIndex:1,
				//CheckType:'CheckResult_Type_Both'
			};
			this.$request.txim({api:'v4/sns/friend_get',params:JSON.stringify(options)});
			
			// if(this.$local.get('user_role')){
			// 	this.user_role=this.$local.get('user_role');
			// }
			// if(this.$local.get('user_id')){
			// 	this.user_id=this.$local.get('user_id');
			// }
			this.getList();			
			
			scroll.scrollToBottom(this.getList);
			
			
		},
		
		mounted() {
			
			setTimeout(()=>{
				this.user_id=this.$local.get('user_id');
				this.user_role=this.$local.get('user_role');
				this.avatar_url=this.$local.get('avatar_url');
			},1000);
		},
		methods: {
			// async getuser(){
			// 	let urls={0:'/api/custuser/getLoginAuthInfo',1:'/api/bususer/getLoginAuthInfo'};
			// 	let res=await this.$request.get(urls[this.user_role],{},{islg:0});
			// 	this.$local.set('user_id',res.data.user_id);			
			// 	this.$local.set('user_role',res.data.user_role);
			// 	this.$local.set('avatar_url',res.data.avatar_url);
				
			// 	this.user_id=res.data.user_id;
			// 	this.user_role=res.data.user_role;
			// 	this.avatar_url=res.data.avatar_url;
			// },
			//删除
			async del(id){
				this.$confirm(this.$t('QDSCM')+'?', this.$t('TISHI'), {
				  confirmButtonText: this.$t('CONFIRM2'),
				  cancelButtonText: this.$t('CANCEL2'),
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.get('/api/trend/deleteTrend',{trend_id:id});
				  this.$message({
					type: 'success',
					message: res.message
				  });
					if(this.tabIndex==3){
						this.getList2();
					}else{
						this.getList('reload');
					}
				}).catch(() => {
				           
				});
			},
			share:function(id,type,title){
				var href=this.$config.website+'home?trend_id='+id;
				this.$request.get('/api/trend/share',{trend_id:id});
				this.$util.share(href,type,title);
			},
			
			beforupload(){
				this.isupload=true;
				this.trend_type=2;
			},
			imgbeforupload(){
				//this.isupload=true;
				this.trend_type=1;
			},
			select_img(){
				document.querySelector('#imgbox input').click();
			},
			select_video(){
				document.querySelector('#videobox input').click();
			},
			todetail(id){
				// this.$emit('detail',id);
				this.$router.push({path:'/home',query:{trend_id:id}});
			},
			tab(n){
				this.tabIndex=n;
				if(this.tabIndex==3){
					this.getList2();
				}else{
					this.getList('reload');
				}
				
			},
			//关注用户
			async guanzhu(id,role,index){
				let res=await this.$request.get('/api/friends/follow',{user_id:id,user_role:role});
				// this.list[index].is_fans=this.list[index].is_fans==1?0:1;
				
				if(this.list[index].is_fans==0){
					this.$util.guanzhu(id);
					this.list[index].is_fans=1;
				}else{
					this.$util.quguan(id);
					this.list[index].is_fans=0;
				}
				
			},
			
			//回复评论成功
			handleSend(){
				this.getCommentList('reload');
			},
			//打开回复评论弹框
			openhuifu(id,name){				
				this.reply_comment_id=id;
				this.reply_comment_name=name;
				this.$refs.huifu.open();
			},
			//点赞评论
			async zanComment(id,index){
				let res=await this.$request.get('/api/trend/favorComment',{trend_comment_id :id});				
				this.comment_list[index].is_favor=this.comment_list[index].is_favor==1?0:1;				
			},
			//点赞动态
			 zan:debounce(async function(id,index){
				
				await this.$request.get('/api/trend/favor',{trend_id:id});
				let res=await this.$request.get('/api/trend/getTrend',{trend_id:id});
				//res.data;
				
				let data=JSON.parse(JSON.stringify(this.list[index]));
				data.is_favor=res.data.is_favor;
				data.favor_desc=res.data.favor_desc;
				data.favor_count=res.data.favor_count;
				
				this.$set(this.list,index,data);
				
				// list.favor_count+=n;
				// this.list[index]=list;
				// if(this.tabIndex==3){
				// 	this.getList2();
				// }else if(this.tabIndex==1){
				// 	this.getList('reload');
				// }
				// this.$message({
				// 	message:res.message,
				// 	type:'success'
				// });				
			}),
			//评论动态
			async sendComment(){
				
				
				let val=this.$refs.biaoqing[0].getval();
				console.log(val);
				//return;
				let res=await this.$request.post('/api/trend/comment',{trend_id:this.trend_id,content:val});
				this.$message({
					message:res.message,
					type:'success'
				});
				this.comment_content='';
				this.getList('reload');
				this.getCommentList('reload');
			},
			//展开收起评论
			toggleComment(trend_id){
				if(this.trend_id==trend_id){
					this.trend_id=0;								
				}else{
					this.comment_content='';
					this.trend_id=trend_id;					
					this.getCommentList('reload');
				}
			},
			//获取动态评论列表
			async getCommentList(t){
				if(t=='reload'){
					this.comment_currentPage=1;
					this.comment_isLast=0;					
				}
				if(this.comment_isLast==1){
					return;
				}
				let res=await this.$request.post('/api/trend/commentPage',{trend_id:this.trend_id,currentPage:this.comment_currentPage,pageSize:this.comment_pageSize});
				
				
				
				if(t=='reload'){
					this.comment_list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.comment_list=[...this.comment_list,...res.data.records];
					}						
				}
				if(this.comment_currentPage>=res.data.totalPage){
					this.comment_isLast=1;
				}else{
					this.comment_currentPage+=1;
				}
				
			},
			uploadImage(response, file, fileList){
				let files=[];
				for (var i = 0; i < fileList.length; i++) {					
					files.push(fileList[i].response.data.Location);
				}
				this.media_files=files;
				console.log(files);
				this.media_files2=[];
			},
			uploadVideo(response, file, fileList){
				this.media_files2=[response.data.Location];
				//console.log(response, file, fileList);
				this.trend_type=2;
				this.FileList=[];
				this.media_files=[];
				this.isupload=false;
			},
		  handleRemove(file, fileList) {
			
				let files=[];
				for (var i = 0; i < fileList.length; i++) {
					files.push(fileList[i].response.data.Location);
				}
				this.media_files=files;
				console.log(files);
		  },
		  handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		  },
		  //发布动态
		  async fabu(){
			 let files=this.media_files;
			 if(this.trend_type==2){
				files=this.media_files2;
			 }
			 let cont=this.content.replace(/\n/g,'<br>');
			 // console.log(cont);
			
			let res=await this.$request.post('/api/trend/publish',{trend_type:this.trend_type,content:cont,media_files:files});
			this.$message({
				message:res.message,
				type:'success'
			});
			this.content='';
			this.media_files=[];
			this.media_files2=[];
			this.FileList=[];
			if(this.tabIndex==3){
				this.getList2();
			}else if(this.tabIndex==1){
				this.getList('reload');
			}
		  },
		  //获取动态广场和关注的动态
		  async getList(t){
			console.log('获取动态');
			if(t=='reload'){
				this.currentPage=1;
				this.isLast=0;		  	
			}
			if(this.isLast==1||this.loading||this.tabIndex==3){
				return;
			}
			let urls={1:'/api/trend/findPage',2:'/api/trend/findFollowPage'};
			this.loading=true;
			let res=await this.$request.post(urls[this.tabIndex],{currentPage:this.currentPage,pageSize:this.pageSize});
			this.loading=false;
			if(t=='reload'){
				this.list=res.data.records;
			}else{
				if(res.data.records.length>0){
					this.list=[...this.list,...res.data.records];
				}
			}
			if(this.currentPage>=res.data.totalPage){
				this.isLast=1;
			}else{
				this.currentPage+=1;
			}
			
		  },
		  //获取发布的动态
		    async getList2(){
		  	let urls={0:'/api/trend/getCustTrendList',1:'/api/trend/getBusTrendList'};
		  	this.loading=true;
		  	let res=await this.$request.get(urls[this.user_role],{user_id:this.user_id});
		  	this.loading=false;
		  	this.list=res.data.records;
		  	
		    }
		
		
		}
	};
</script>

<style lang="less" >
	.right_img_list{
		.el-upload{
			width: 82px;
			height: 82px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.el-upload-list__item{
			width: 82px;
			height: 82px;
		}
	}
	
	.home_msg_list{
		width: 643px;
		margin: 0 50px;
		.home_list_add{
			width: 100%;
			border-radius: 7px;
			box-sizing: border-box;
			padding: 17px 35px;
			background-color: #F3F1EA;
			display: flex;
			justify-content: space-between;
			margin-bottom: 17px;
			.user{
				width: 42px;
				height: 42px;
				margin-right: 22px;
				flex-shrink: 0;
				border-radius: 2px;
			}
			.list_right{
				width: 100%;
				.input{
					width: 100%;
					margin-bottom: 12px;
				}
				
				.right_last{
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 15px;
					.el-button{
						background-color: #567BB6;
						border: none;
					}
					.last_menu{
						display: flex;
						align-items: center;
						.menu{
							display: flex;
							align-items: center;
							margin-right: 30px;
							font-size: 14px;
							font-weight: bold;
							.list_img{
								
								
								margin-right: 9px;
							}
						}
					}
				}
			}
		}
		.home_list{
			width: 100%;
			border-radius: 7px;
			box-sizing: border-box;
			padding: 17px 35px;
			background-color: #F3F1EA;
			margin-bottom: 17px;
			.activity_box{
				padding: 15px;
				background-color: #000000;
				border-radius: 5px;
				margin: 10px 0;
				display: flex;
				justify-content: space-between;
				.box_img{
					width: 100px;
					height: 100px;
					border-radius: 3px;
					object-fit: cover;
				}
				.box_msg{
					width: 170px;
					font-size: 12px;
					color: #fff;
					.msg_name{
						text-overflow: -o-ellipsis-lastline;
						  overflow: hidden;
						  text-overflow: ellipsis;
						  display: -webkit-box;
						  -webkit-line-clamp: 2;
						  line-clamp: 2;
						  -webkit-box-orient: vertical;
						  line-height: 1.3;
						  margin-bottom: 10px;
					}
					.msg_msg{
						width: 170px;
						font-size: 10px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						line-height: 1.3;
						margin-bottom: 10px;
					}
					.msg_text{
						font-size: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
				.box_center{
					width: 180px;
					border-left: 1px solid #606060;
					border-right: 1px solid #606060;
					padding: 0 10px;
					color: #fff;
					font-size: 10px;
					.center_text{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 5px;
						.text_text{
							width: 50px;
							flex-shrink: 0;
						}
						.text_box{
							border-radius: 3px;
							width: 130px;
							line-height: 20px;
							border: 1px solid #344A6E;
							padding-left: 5px;
							font-size: 10px;
						}
					}
				}
				.box_btn{
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 40px;
					color: #fff;
					font-size: 10px;
					.btn{
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 5px 0;
						.fenx{
							width: 20px;
							height: 20px;
							margin-bottom: 10px;
						}
					}
				}
			}
			.list_title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 17px;
				.title_img{
					width: 42px;
					height: 42px;
					border-radius: 2px;
					margin-right: 10px;
					object-fit: cover;
				}
				.list_user{
					
					.user{
						font-size: 14px;
						margin-bottom: 10px;
						font-weight: bold;
					}
					.user_msg{
						font-size: 12px;
					}
				}
				
				.el-button{
					background-color: #567BB6;
					border: none;
				}
				.active{
					color: #2D2D2E;
					background-color: #F3F1EA;
					border: 1px solid #567BB6;
				}
			}
			.list_text{
				font-size: 14px;
				line-height: 1.3;
				margin-bottom: 20px;
			}
			.img_list{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.img{
					width: 280px;
					height: 190px;
					margin-bottom: 9px;
					object-fit: cover;
				}
			}
			.like_box{
				height: 30px;
				padding: 0 8px;
				display: flex;
				align-items: center;
				border-top: 1px solid #567BB6;
				font-size: 12px;
				.like_img{
					width: 20px;
					height: 20px;
					margin-right: 6px;
				}
			}
			.btn_box{
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 45px;
				border-top: 1px solid #567BB6;
				border-bottom: 1px solid #567BB6;
				.btn_left{
					display: flex;
					align-items: center;
					margin-left: 85px;
					.left_menu{
						display: flex;
						align-items: center;
						margin-right: 70px;
						font-size: 14px;
						font-weight: bold;
						position: relative;
						&:hover .sharemenu{display: block;}
						.btn1{
							width: 24px;
							height: 24px;
							margin-right: 8px;
							cursor: pointer;
						}
						.btn2{
							width: 21px;
							height: 21px;
							margin-right: 8px;
							cursor: pointer;
						}
						.btn3{
							cursor: pointer;
							width: 19px;
							height: 22px;
							margin-right: 8px;
						}
					}
				}
				.right_btn{
					width: 27px;
					height: 27px;
					cursor: pointer;
				}
			}
			.input_box{
				width: 100%;
				height: 38px;
				background: #FFFFFF;
				border-radius: 9px;
				margin: 15px 0;
				box-sizing: border-box;
				font-size: 14px;
			}
			.input_add{
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 42px;
				border-bottom: 1px solid #567BB6;
				.biaoqin{
					width: 25px;
					height: 25px;
				}
				.el-button{
					background-color: #567BB6;
					border: none;
				}
			}
			.pin_list{
				width: 100%;
				.list_box{
					width: 100%;
					margin: 15px 0;
					display: flex;
					.box_img{
						width: 34px;
						height: 34px;
						margin-right: 15px;
						border-radius: 2px;
						flex-shrink: 0;
					}
					.box_right{
						width: 100%;
						.right_user{
							display: flex;
							justify-content: space-between;
							align-items: center;
							.user_name{
								.name{
									
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: normal;
									font-size: 12px;
									margin-bottom: 10px;
									font-weight: bold;
								}
								.name_time{
									font-size: 10px;
								}
							}
							.user_btn{
								display: flex;
								align-items: center;
								.btn1{
									width: 24px;
									height: 24px;
									margin-left: 20px;
								}
								.btn2{
									width: 21px;
									height: 21px;
									margin-left: 20px;
								}
								.btn3{
									width: 24px;
									height: 24px;
									margin-left: 20px;
								}
							}
						}
						.right_msg{
							font-size: 12px;
							margin: 10px 0;
							line-height: 1.3;
							img{width: 22px;height: 22px;vertical-align: middle;}
						}
						.right_more{
							font-size: 10px;
							display: flex;
							align-items: center;
							border-left: 1px solid #DADADA;
							.more_user{
								color: #567BB6;
								margin: 0 5px;
							}
						}
					}
				}
			}
			.pin_more{
				font-size: 14px;
				text-align: center;
				padding-top: 15px;
				border-top: 1px solid #567BB6;
				.el-button{
					background-color: #F3F1EA;
					border: none;
					font-size: 14px;
					color: #2D2D2E;
					font-weight: bold;
				}
			}
		}
	
	}
</style>